<!--
 * @Description: 页面列表
 * @Author: luocheng
 * @Date: 2021-09-16 14:10:26
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-11-03 16:09:01
-->
<template>
	<div class="page-list">
		<el-form
			:inline="true"
			:model="search"
			@submit.native.prevent
			id="page-filter-box"
		>
			<el-form-item>
				<el-radio-group v-model="filterType">
					<el-radio label="d1f7e149-2af7-468e-85be-5ef9bd97f634" @change="onFilterType">按名称</el-radio>
<!--					<el-radio label="3402cf7d-3b26-4d3c-82cb-72f37a7cbcb0" @change="onFilterType">按ID</el-radio>-->
					<el-radio label="field6193612ab8dcc" @change="onFilterType">按UUID</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item>
				<el-input v-model="search.title" placeholder="请输入" clearable @input="onSearch"></el-input>
			</el-form-item>
			<el-form-item>
				<el-radio-group v-model="search.pageType" @change="getList">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="page">常规页面 </el-radio-button>
					<el-radio-button label="chart">图表</el-radio-button>
					<el-radio-button label="dataview">大屏页面</el-radio-button>
					<el-radio-button label="console">中控台</el-radio-button>
					<el-radio-button label="template">页面模板</el-radio-button>
					<el-radio-button label="second">第二屏</el-radio-button>
					<el-radio-button label="mobile">H5页面</el-radio-button>
					<el-radio-button label="card">循环模版</el-radio-button>
				</el-radio-group>
			</el-form-item>
<!--			<el-form-item>
				<el-checkbox v-model="search.notTest" label="不含测试" border @change="getList"></el-checkbox>
			</el-form-item>-->
			<el-form-item>
				<el-button type="primary" @click="onSearch">搜索</el-button>
				<el-button @click="onReset">重置</el-button>
				<el-button type="success" @click="onCreate(null)">新建</el-button>
			</el-form-item>
		</el-form>
		<el-tabs
			v-if="false"
			v-model="activeName"
			@tab-click="getList"
		>
			<el-tab-pane
				label="普通页面"
				name="''"
			></el-tab-pane>
			<el-tab-pane
				label="自定义图表"
				name="chart"
			></el-tab-pane>
		</el-tabs>
		<article class="list-box">
			<el-table
				v-loading="loading"
				:data="tableData"
				style="width: 100%"
				row-key="id"
				border
				:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
				:row-class-name="setRowClass"
				:height="tableHeight"
			>
				<el-table-column prop="id" label="ID" align="center" width="60"></el-table-column>
				<el-table-column prop="uuid" label="UUID" align="center" width="180"></el-table-column>
				<el-table-column prop="name" label="页面名称" align="center"></el-table-column>
				<el-table-column prop="used_count" label="关联次数" align="center" width="80">
					<template slot-scope="{ row }">
						<span :class="{
							'used-count': +row.used_count > 0
						}">{{ row.used_count }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="创建时间" align="center" width="180"></el-table-column>
				<el-table-column prop="updated_at" label="最后编辑时间" align="center" width="180"></el-table-column>
				<el-table-column align="center" label="操作" width="240">
					<template slot-scope="{ row }">
						<el-button type="text" size="mini" style="color: #67C23A" @click="onCreate(row)">修改名称</el-button>
							<!-- v-if="row.page_type !== 'chart' && !row.is_template" -->
						<el-button
							type="text"
							size="mini"
							style="color:#E6A23C"
							@click="onCopy(row)"
						>复制</el-button>
						<el-button
							type="text"
							size="mini"
							style="color:#409EFF"
							@click="onModify(row)"
						>编辑</el-button>
						<el-button
							v-if="!row.used_count"
							type="text"
							size="mini"
							style="color:#F56C6C"
							@click="onRemove(row)"
						>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				@size-change="onSize"
				@current-change="onPage"
				:current-page="+pager.current_page"
				:page-sizes="[20, 30, 50, 100]"
				:page-size="+pager.per_page"
				layout="total, sizes, prev, pager, next, jumper"
				:total="+pager.total"
			>
			</el-pagination>
		</article>
		<!-- 创建 -->
		<el-dialog title="创建页面" :visible.sync="showModify" center width="600px">
			<el-form :model="createForm" label-width="120px" ref="createForm" :rules="rules">
				<el-form-item label="页面名称" prop="title">
					<el-input v-model="createForm.title" placeholder="请输入页面名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="页面类型" prop="editorType" v-if="modifyType === 'createData'" class="editor-type-item">
					<!-- 选择模板 -->
					<el-radio-group v-model="createForm.editorType">
						<el-radio label="page">常规页面</el-radio>
						<!-- <el-radio label="analysis">数据分析</el-radio> -->
						<el-radio label="chart">图表</el-radio>
						<el-radio label="dataview">大屏页面</el-radio>
						<el-radio label="console">中控台</el-radio>
						<el-radio label="template">页面模板</el-radio>
						<el-radio label="second">第二屏</el-radio>
						<el-radio label="mobile">H5页面</el-radio>
						<el-radio label="card">循环模版</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="预设权限">
					<SetPermissions v-model="createForm.permissions"></SetPermissions>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showModify = false">取 消</el-button>
				<el-button type="primary" v-if="modifyType === 'createData'" @click="onConfirmCreate(false)">确定</el-button>
				<el-button type="primary" v-else @click="onConfirmModify">确定</el-button>
			</span>
		</el-dialog>
		<!-- 迁移页面 -->
		<el-dialog title="迁移页面" :visible.sync="showTransfer" center width="400px">
			<el-form :model="transferForm" label-width="120px" ref="transferFormDom" :rules="transferRules">
				<el-form-item label="目标服务器" prop="serverName">
					<el-select v-model="transferForm.serverName" placeholder="请选择迁移服务器" clearable>
						<el-option v-for="(item, index) in serverList"
							:key="index"
							:label="item.name"
							:value="item.name"
						>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showTransfer = false">取 消</el-button>
				<el-button type="primary" @click="onConfirmTransfer">确定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { dataInterface, transferPage } from '@/apis/data';
import { mapState } from 'vuex';
import { Radio, RadioButton, RadioGroup, TabPane, Tabs } from 'element-ui';
import SetPermissions from '@/components/common/SetPermissions';
// import GlobalFactory from "@/global/GlobalFactory.vue";

export default {
	name: 'PageList',
	components: {
		'el-radio-group': RadioGroup,
		'el-radio': Radio,
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		'el-radio-button': RadioButton,
		SetPermissions
	},
	data() {
		return {
			// 搜索
			search: {
				title: '',
				pageType: '',
				notTest: false
			},
			// 表格数据
			tableData: [],
			// 显示创建页面弹窗
			showModify: false,
			// 类型 dataview 大屏项目 page 常规页面
			createForm: {
				editorType: 'page',
				title: '',
				permissions: ''
			},
			rules: {
				title: [
					{ required: true, message: '请输入页面名称', trigger: 'blur' },
					{ min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
				],
				editorType: [{ required: true, message: '请选择页面类型', trigger: 'change' }]
			},
			// 分页
			pager: {
				current_page: 1,
				per_page: 20,
				total: 0
			},
			// 字段列表
			metadata: {},
			// 页面数据
			componentData: [],
			activeName: '',
			// 筛选类型
			filterType: 'd1f7e149-2af7-468e-85be-5ef9bd97f634',
			// 编辑类型
			modifyType: 'createData',
			// 表格高度
			tableHeight: 'calc(100vh - 235px)',
			// 显示迁移
			showTransfer: false,
			// 迁移页面
			transferPage: null,
			// 迁移配置
			transferForm: {
				serverName: ''
			},
			// 填写规则
			transferRules: {
				serverName: [{ required: true, message: '请选择需要迁移的服务器', trigger: 'change' }]
			},
			// 可选服务器列表
			serverList: [],
		};
	},
	created() {
		this.getList();
	},
	computed: {
		...mapState(['canvasStyle'])
	},
	mounted() {
		this.resetTableHeight();
		window.onresize = () => {
			this.resetTableHeight();
		};
	},
	methods: {
		/**
		 * @desc: 计算表格高度
		 */
		resetTableHeight() {
			this.$nextTick(() => {
				const box = document.querySelector('#page-filter-box');
				if (box && box.offsetHeight) {
					this.tableHeight = `calc(100vh - ${box.offsetHeight +60 + 30 + 20 + 72 }px)`
				}
			});
		},
		/**
		 * @desc: 设置行类名
		 * @param {Object} row 行数据
		 * @return {String} 类名
		 */
		setRowClass({ row }) {
			if (row && row.name && row.name.includes('测试')) {
				return 'table-test-page'
			}
			if (row && row.name && row.name.includes('开发')) {
				return 'table-develop-page'
			}
			if (row?.is_template) {
				return 'table-template-page';
			}
			return '';
		},
		/**
		 * @desc: 切换类型
		 */
		onFilterType() {
			if (!this.search.title) return;
			this.getList();
		},
		/**
		 * @desc: 获取页面列表
		 */
		getList() {
			this.loading = true;
			this.tableData = [];
			const search = [
				{
					field_uuid: this.filterType,
					ruleType: 'like',
					value: this.search.title
				},
				// 类型
				{
					field_uuid: 'field61bc41f645e17',
					ruleType: 'like',
					value: this.search.pageType
				}
			];
			// 不包含测试
			if (this.search.notTest) {
				search.push({
					field_uuid: 'd1f7e149-2af7-468e-85be-5ef9bd97f634',
					ruleType: 'notlike',
					value: '测试'
				})
			}
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61922b1881103',
				search,
				page: this.pager.current_page || 1,
				size: this.pager.per_page || 20,
				transcode: 0
			}).then((res) => {
				this.loading = false;
				if (res && res.status === 200 && res.data && res.data.data) {
					this.tableData = res.data.data.data || [];
					this.metadata = res.data.metadata;
					this.pager = {
						current_page: res.data.data.current_page,
						per_page: res.data.data.per_page,
						total: res.data.data.total
					};
				}
			}).catch((err) => {
				this.loading = false;
				this.$message.error(`获取列表:${err.msg}`);
			});
		},
		/**
		 * @desc: 复制
		 * @param {Object} data 页面对象
		 */
		onCopy(data) {
			this.$confirm(`是否确认复制页面-【${data.name}】?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				const page_data = await this.getPageConfig(data.id);
				if (data.page_type === 'chart') {
					page_data.name = `${page_data.name}-复制`;
					this.createForm = {
						editorType: page_data.page_type,
						title: `${page_data.name}`,
						permissions: page_data.permissions || ''
					};
					this.onConfirmCreate(true, {
						copyData: {
							...page_data.page_data
						},
						permissions: page_data.permissions
					});
				} else {
					// 常规配置
					const pageData = page_data.page_data;
					pageData.editorConfig.title = `${pageData.editorConfig.title}-复制`;
					this.createForm = {
						editorType: pageData.editorType,
						title: pageData.editorConfig.title,
						permissions: pageData.editorConfig.permissions
					};
					this.onConfirmCreate(true, {
						copyData: {
							editorType: this.createForm.editorType,
							componentData: pageData.componentData,
							editorConfig: pageData.editorConfig
						},
						permissions: page_data.permissions
					});
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		/**
		 * @desc: 确认创建
		 */
		onConfirmCreate(isCopy, dataObj) {
			const { copyData, permissions = [] } = dataObj || {};
			const valid = isCopy || this.$refs.createForm.validate();
			if (!valid) return;
			this.$store.commit('createPage', {
				...this.createForm,
				editorConfig: {
					title: this.createForm.title
				},
				statusConfig: []
			});
			// 保存
			let params = isCopy ? copyData : {
					editorType: this.createForm.editorType,
					componentData: this.componentData,
					editorConfig: this.canvasStyle,
					statusConfig: []
			};
			if (this.createForm.editorType === 'chart') {
				// 图表
				params = isCopy ? copyData : {
					chartData: null
				}
			} else if (this.createForm.editorType === 'dataview' || this.createForm.editorType === 'console') {
				// 大屏页面
				params = isCopy ? copyData : {
					editorType: this.createForm.editorType,
					componentData: this.componentData,
					editorConfig: {
						...this.canvasStyle,
						backgroundColor: '#0E2A43'
					}
				};
			}else {
				params = isCopy ? copyData : {
					editorType: this.createForm.editorType,
					componentData: this.componentData,
					editorConfig: this.canvasStyle
				};
			}
			this.$loading();
			dataInterface({
				__method_name__: 'createData',
				data_id: '',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				page_data: JSON.stringify(params), // 配置数据
				page_type: this.createForm.editorType,
				is_template: +(this.createForm.editorType === 'template'),
				permissions: this.createForm.permissions || permissions || '',
				name: this.createForm.title // 页面名称
			}).then((res) => {
				this.$loading().close();
				if (res.status === 200) {
					this.showModify = false;
					this.getList();
				}
			}).catch((err) => {
				this.$loading().close();
				this.$message.error(`保存失败:${err.msg}`);
			});
		},
		/**
		 * @desc: 获取页面配置数据
		 */
		getPageConfig(pageId) {
			return new Promise((resolve, reject) => {
				dataInterface({
					__method_name__: 'dataInfo',
					data_id: pageId,
					object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
					view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
					transcode: 0
				}).then((res) => {
					if (!res) {
						resolve('{}');
						return;
					}
					resolve(res.data.data);
				}).catch((err) => {
					console.log(err, '----1111----');
					reject();
				});
			});
		},
		/**
		 * @desc: 编辑
		 * @param {Object} data
		 */
		onModify(data) {
			if (!data) {
				this.$message.error('出错了!');
				return;
			}
			const { id, uuid, used_count, page_type } = data;
			if (used_count) {
				this.$confirm(`本页面被调用【${used_count}次】,请谨慎操作！`).then(() => {
					this.goModify({ id, uuid, page_type });
				}).catch(() => {})
			} else {
				this.goModify({ id, uuid, page_type });
			}
		},
		/**
		 * @desc: 前往编辑页
		 */
		goModify({ page_type, id, uuid }) {
			if (page_type == 'chart') {
				// 配置图表
				this.$router.push({
					name: 'ConfigChart',
					query: {
						id,
						pageUUID: uuid
					}
				})
			} else {
				// 常规页面
				sessionStorage.setItem('isModifySite', 1);
				this.$router.push({
					name: 'ModifyPage',
					query: {
						id,
						pageUUID: uuid
					}
				});
			}
		},
		/**
		 * @desc: 删除
		 * @param {Object} data
		 */
		onRemove(data) {
			this.$prompt(`是否确认删除页面————【${data.name}】，请在输入框中输入 “ 确认删除 ” !`, '提示', {
				confirmButtonText: '确认删除',
				inputValidator: val => {
					return val === '确认删除';
				},
				inputErrorMessage: '输入错误'
			})
				.then(() => {
					this.$loading();
					dataInterface({
						__method_name__: 'deleteData',
						object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
						data_id: data.id
					})
						.then(() => {
							this.getList();
							this.$loading().close();
						})
						.catch(() => {
							this.$loading().close();
						});
				})
				.catch(() => {});
		},
		/**
		 * @desc: 创建/编辑
		 * @param {Object/null} data 数据值
		 */
		onCreate(data) {
			this.showModify = true;
			if (data) {
				this.modifyType = 'updateData';
				this.createForm = {
					editorType: data.page_type,
					data_id: data.id,
					title: data.name,
					permissions: data.permissions
				}
			} else {
				this.createForm = {
					title: '',
					editorType: 'page'
				};
				this.modifyType = 'createData';
			}
		},
		/**
		 * @desc: 确认编辑
		 */
		onConfirmModify() {
			const valid = this.$refs.createForm.validate();
			if (!valid) return;
			dataInterface({
				__method_name__: 'updateData',
				data_id: this.createForm.data_id,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				permissions: this.createForm.permissions,
				name: this.createForm.title // 页面名称
			})
				.then((res) => {
					this.$loading().close();
					if (res.status === 200) {
						this.showModify = false;
						this.getList();
					}
				})
				.catch((err) => {
					this.$loading().close();
					this.$message.error(`保存失败:${err.msg}`);
				});
		},
		/**
		 * @desc: 搜索
		 */
		onSearch() {
			this.pager.current_page = 1;
			this.getList();
		},
		/**
		 * @desc: 重置
		 */
		onReset() {
			this.search.title = '';
			this.onSearch();
		},
		/**
		 * @desc: 切换条数
		 * @param {Number} size 条数
		 */
		onSize(size) {
			this.pager.current_page = 1;
			this.pager.per_page = size;
			this.getList();
		},
		/**
		 * @desc: 切换特码
		 * @param {Number} page 页码
		 */
		onPage(page) {
			this.pager.current_page = page;
			this.getList();
		},
		/**
		 * @desc: 迁移页面
		 * @param {Object} row
		 */
		onTransfer(row) {
			if (!row) return false;
			const serverList = localStorage.getItem('serverList');
			if (!serverList) {
				this.$message.error('暂无可选服务器!');
				return false;
			}
			this.serverList = JSON.parse(serverList);
			this.showTransfer = true;
			this.transferPage = row;
		},
		/**
		 * @desc: 迁移
		 */
		onConfirmTransfer() {
			this.$refs.transferFormDom.validate(valid => {
				if (!valid) return;
				this.$confirm(`是否确认迁移【${this.transferPage.name}】页面到【${this.transferForm.serverName}】服务器?`).then(() => {
					console.log('确认----')
					const server = this.serverList.find(ele => ele.name === this.transferForm.serverName);
					const params = {
						page_uuid: this.transferPage.uuid,
						target_metadatatoken: server.metadatatoken,
						target_server: server.originURl
					};
					transferPage(params).then(res => {
						this.$loading().close();
						console.log(res, '------')
						this.$message.success('迁过去了！快去看！');
					}).catch(err => {
						this.$message.error('迁移失败!');
						console.log(err);
						this.$loading().close();
					});
					this.showTransfer = false;
				}).catch(() => {})
			});
		}
	}
};
</script>

<style lang="less" scoped>
.page-list {
	background-color: #FFFFFF;
  padding: 20px;
	display: flex;
	flex-direction: column;
	.el-form {
		text-align: left;
	}
	.list-box {
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
}
span.used-count{
	color: #67C23A;
	font-weight: 900;
	font-size: 16px;
}
.editor-type-item{
	.el-radio-group{
		display: flex;
		flex-wrap: wrap;
		.el-radio{
			padding: 10px 0;
		}
	}
}
</style>
<style>
.table-test-page{
	background-color: rgb(250, 236, 216)!important;
}
.table-develop-page{
	background-color: #F56C6C!important;
}
.table-template-page, .table-template-page:hover{
	background-color: rgb(225, 243, 216)!important;
}
</style>
